<template>
  <div
    class="d-flex align-content-stretch flex-wrap mb-3"
    style="height: calc(100vh - 90px)">

    <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-9">

      <div class="card">
        <div class="card-header">
          掲示板 (直近三か月)
          <button
            type="button"
            class="btn btn-sm"
            @click="onTweetReloadClick">
            <i class="bi bi-arrow-clockwise"></i>
          </button>
        </div>
        <ul
          class="list-group list-group-flush"
          style="height: calc(100vh - 130px);overflow-y: scroll;">
          <li class="list-group-item">
            <div class="input-group">
              <textarea
                class="form-control"
                aria-label="With textarea"
                v-model="postContent"></textarea>
              <button
                class="btn btn-outline-success"
                type="button"
                @click="onPostClick">
                投稿
              </button>
            </div>
          </li>
          <li
            class="list-group-item"
            v-if="tweetList.length === 0 && isTweetLoaded === false">
            <img src="../assets/spinner.gif">
          </li>
          <li
            class="list-group-item"
            v-if="tweetList.length === 0 && isTweetLoaded === true">
            <p>直近3ヶ月の投稿はありません</p>
          </li>
          <li
            class="list-group-item"
            style="text-align: left;"
            v-else
            v-for="r in tweetList"
            :key="r.id">
            <div class="d-flex w-100 justify-content-between">
              <small style="font-weight: bold;">{{ r.userName }}</small>
            </div>
            <p class="mb-1" v-html="r.content">
            </p>
            <small class="text-black-50 bg-white">
              {{ r.createdAtDisp }}
            </small>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
      <div class="card">
        <div class="card-header">
          新着予約
          <button
            type="button"
            class="btn btn-sm"
            @click="onNewReserveReloadClick">
            <i class="bi bi-arrow-clockwise"></i>
          </button>
        </div>
        <div
          class="card-body"
          style="height: calc(100vh - 130px);overflow-y: scroll;">
          <template v-if="newReserves.length === 0 && isNewsLoaded === false">
            <img src="../assets/spinner.gif">
          </template>
          <template v-if="newReserves.length === 0 && isNewsLoaded === true">
            <p>直近に登録された予約はありません</p>
          </template>
          <template v-else>
            <div class="row g-0" v-for="r in newReserves" :key="r.reserveId">
              <div class="col-12 mb-1">
                <div :class="`card ${r.cardColor}`">
                  <div class="card-body" style="text-align: left;">
                    <div class="row g-0">
                      <div class="col">
                        <p style="font-size: 0.8rem;margin-bottom: 0;" v-if="r.isTakeLater">
                          預り祈祷
                        </p>
                        <p style="font-size: 0.8rem;margin-bottom: 0;" v-else>
                          祈祷日：{{ r.startDateDisplay }} {{ r.startTimeDisplay }}
                        </p>
                        <p style="font-size: 0.8rem;margin-bottom: 0;">
                          願意：{{ r.mainWishName }} {{ r.subWishName }}
                        </p>
                        <p style="font-size: 0.9rem;margin-bottom: 0;">
                          {{ r.prayerName }}
                        </p>
                      </div>
                      <div class="col-auto">
                        <a
                          :href="`/reserve/edit/${r.reserveId}`"
                          target="_blank"
                          class="btn btn-sm btn-outline-success">
                          <i class="bi bi-pencil-square"></i>
                        </a>
                      </div>
                    </div>
                    <div class="row g-0">
                      <div class="col-auto" v-if="r.parentReserveId !== 0">
                        <span class="badge text-bg-light">
                          <a
                            :href="`/reserve/edit/${r.parentReserveId}`"
                            target="_blank">
                            追加予約
                          </a>
                        </span>
                      </div>
                      <div class="col"></div>
                      <div class="col-auto">
                        <span class="card-timestamp">{{ r.registeredTimeStamp }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import httpClient from '../lib/httpClient'
import loadingPanel from '../lib/loadingPanel'
import toast from '../lib/toast'

export default {
  setup () {
    const store = useStore()
    const newReserves = ref([])
    const tweetList = ref([])

    const isNewsLoaded = ref(false)
    const isTweetLoaded = ref(false)

    const postContent = ref('')

    onMounted(() => {
      setTimeout(loadNewReserve, 400)
      setTimeout(loadTweet, 600)

      loadingPanel.loadComplete()
    })

    async function loadNewReserve () {
      newReserves.value = []
      isNewsLoaded.value = false
      const res = await httpClient.get(
        '/api/v1/Reserve/LoadLatest',
        store.getters.token)
      isNewsLoaded.value = true
      newReserves.value = res.data.payload
    }

    async function loadTweet () {
      tweetList.value = []
      const res = await httpClient.get(
        '/api/v1/BulletinBoard/LoadLatest?lastDt=',
        store.getters.token)

      tweetList.value = res.data.payload
    }

    async function onPostClick () {
      if (postContent.value === '') {
        toast.showDangerToast({ message: '投稿内容を入力してください' })
        return
      }
      loadingPanel.loadStart()
      const res = await httpClient.post(
        '/api/v1/BulletinBoard/Create',
        {
          content: postContent.value
        },
        store.getters.token)

      if (!res.isValid) {
        loadingPanel.loadComplete()
        toast.showDangerToast({ message: res.data.summaryMessage })
        return
      }
      postContent.value = ''
      toast.showSuccessToast({ message: '投稿しました' })
      setTimeout(loadTweet, 200)
      loadingPanel.loadComplete()
    }

    async function onNewReserveReloadClick () {
      newReserves.value = []
      setTimeout(loadNewReserve, 200)
    }

    async function onTweetReloadClick () {
      tweetList.value = []
      setTimeout(loadTweet, 200)
    }

    return {
      newReserves,
      tweetList,
      postContent,
      isNewsLoaded,
      isTweetLoaded,
      onNewReserveReloadClick,
      onTweetReloadClick,
      onPostClick
    }
  }
}
</script>
<style scoped>
.bg-status-info {
  background: var(--bs-info-bg-subtle);
  border-color: var(--bs-info);
}

.bg-status-danger {
  background: var(--bs-danger-bg-subtle);
  border-color: var(--bs-danger);
}

.card-timestamp {
  font-size: .7rem;
  color: grey;
}
</style>
